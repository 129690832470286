<template>
    <v-app>
      <Navbar />
      <v-main class="sewilla" >
        <v-container class="fill-height" fluid>
          <v-row justify="center">
            <v-col cols="12" md="8" lg="6" class="text-center">
              <h1 class="display-1">Ile do Sewilli?</h1>
              <h1 class="display-1" v-if="timeRemaining > 0">Do wyjazdu zostało:</h1>
              <h2 v-if="timeRemaining > 0">
            {{ dni }} dni, {{ godziny }} godzin, {{ minuty }} minut, {{ sekundy }} sekund
              </h2>
            <h2 v-else>
            Protokół Rozpoczęty
            </h2>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
      <Footer />
    </v-app>
  </template>
  
  <script>
  import Navbar from './components/Navbar.vue';
  import Footer from './components/Footer.vue';
  
  export default {
    name: 'Ho-me',
    components: {
      Navbar,
      Footer,
    },
    data() {
    return {
      wyjazd: new Date("Nov 18, 2024 06:00:00").getTime(),
      timeRemaining: 0,
      dni: 0,
      godziny: 0,
      minuty: 0,
      sekundy: 0,
    };
  },
  mounted() {
    this.updateCountdown();
    this.intervalId = setInterval(() => {
      this.updateCountdown();
    }, 1000);
  },
  methods: {
    updateCountdown() {
      const teraz = new Date().getTime();
      this.timeRemaining = this.wyjazd - teraz;

      if (this.timeRemaining > 0) {
        this.dni = Math.floor(this.timeRemaining / (1000 * 60 * 60 * 24));
        this.godziny = Math.floor((this.timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minuty = Math.floor((this.timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        this.sekundy = Math.floor((this.timeRemaining % (1000 * 60)) / 1000);
      } else {
        clearInterval(this.intervalId);
        this.timeRemaining = 0;
      }
    },
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },}

  </script>
  
  <style scoped>
  
  .sewilla{
    background-color: midnightblue;
    color: white;
  }
  
  .fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-out forwards;
  }
  
  .fade-in-delayed {
    opacity: 0;
    animation: fadeIn 1s ease-out forwards;
    animation-delay: 0.5s;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  </style>