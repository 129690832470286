<template>
    <v-app>
    <Navbar />
    <v-main class="home">
      <v-container>
        <v-row justify="center">
          <h1 class="display-1">Adam Malysz</h1>
        </v-row>
        <v-row justify="center">
          <v-col class="text-center">
          <div id="kontener1">
        <input placeholder="Ilu zawodników?" id="a" class="malyszinput">
        <input type="button" value="Start" @click="setup()" class="malyszinput" id="b">
        </div>
    </v-col>
    <v-col>
    <div id="kontener2"></div>
    </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="8" lg="6" class="text-center">
          <div id="scoreboard"></div>
        </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import {malyszsetup, fis} from './malysz';

export default {
  name: 'Mal-ysz',
  components: {
    Navbar,
    Footer,
  },
  methods: {
    setup(){
      malyszsetup()
    },
    fis(){
      fis
    }
  }
};

</script>

<style scoped>
.home{
  background-color: midnightblue;
  color: white;
}
#scoreboard{
  font-size: 150%;
  font-family: "Comic Sans MS", serif;
}
.malyszinput{
  border: 2px gray solid;
  border-radius: 4px;
}
.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}

.fade-in-delayed {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
  animation-delay: 0.5s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
</style>