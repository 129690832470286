import { createRouter, createWebHistory } from 'vue-router';
import Home from './Home.vue';
import Malysz from './Malysz.vue';
import About from './About.vue';
import Projects from './Projects.vue';
import Sewilla from './Sewilla.vue';
import NotFound from './404.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/adammalysz',
    name: 'Malysz',
    component: Malysz,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
  },
  {
    path: '/iledosewilli',
    name: 'Sewilla',
    component: Sewilla,
  },
  {
    path: "/:notFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;