//Dynamiczny Formularz
    export function malyszsetup() {
        const div_docelowy = document.getElementById("kontener2");
        const liczba_pol = document.getElementById("a").value;
        div_docelowy.innerHTML = "";
        for(let i = 1; i <= liczba_pol; i++) {
            const input = document.createElement("input");
            input.type = "text";
            input.placeholder = `Zawodnik #${i}`;
            input.id = `zaw${i}`;
            input.style = "border: 2px gray solid;border-radius: 4px;"
            div_docelowy.appendChild(input);} 
         const button = document.createElement("input");
         button.type = "button";
         button.style = "border: 2px gray solid;border-radius: 4px;"
         button.value = "Starten Machen";
         button.onclick = fis;
         div_docelowy.appendChild(button)}
    export function fis() {
        const liczba_pol = document.getElementById("a").value
        const zawodnicy = [];
        const zawodnicy_od = [];
        const punkty = [];
        const wiatr = [];
    // zapisywanie nazw zawodników
        for(let i = 1; i <= liczba_pol; i++){
            zawodnicy[i] = (document.getElementById(`zaw${i}`).value)}
    // Generowanie Odległości
        let min = Math.ceil(100);
        let max = Math.floor(150);
        for (let i = 1; i <= liczba_pol; i++){
            let temp = (Math.random() * (max - min) + min).toFixed(2)
            let temp2 = Math.round(temp*2)/2
            zawodnicy_od[i] = temp2
            punkty[i] = 1.8 * (temp2 - 120)  + 60}
    // Sędziowie FIS
        let min1 = Math.ceil(14);
        let max1 = Math.floor(20);
        const sedziowie = [];
        const sedziowiefinal = [];
        const notysedziowie = [];
        for (let i = 0; i <=liczba_pol; i++) {
            for (let i = 0; i <= 4; i++) {
                let temp = (Math.random() * (max1 - min1) + min1).toFixed(2)
                sedziowie[i] = Math.round(temp * 2) / 2}
            for (let i = 1; i <= 1; i++) {
                sedziowie.sort();
                delete sedziowie[0];
                delete sedziowie[4];}
                sedziowie.sort();
                notysedziowie[i] = sedziowie[0] + ", " + sedziowie[1] + ", " + sedziowie[2] + " "
            let notasedziowska = sedziowie.reduce(function (x, y) {
                return x + y;})
            sedziowiefinal[i] = notasedziowska
            punkty[i] += notasedziowska}
    // Wiatr
        for (let i= 1; i <= liczba_pol; i++){
        let temp4 = 0;
        let min1 = -5; let max1 = 5
        let wiatrv = (Math.random() * (max1 - min1) + min1).toFixed(1)
            wiatr[i] = wiatrv;
            temp4 = parseFloat(wiatrv).toFixed(1) * -11
            punkty[i] += temp4;}
    // Sortowanie Końcowe
        const compare = [];
        const finish = [];
        for(let i= 1; i<= liczba_pol; i++){
            compare[i] = punkty[i]
            compare.sort(function(a1, a2){return a1-a2});}
        compare.reverse();
        let temp4, temp5;
        for(let i =1; i<= liczba_pol;i++){
            temp5 = punkty[i]
            temp4 = compare.indexOf(temp5)
            finish[i] = temp4}
        let temp7, temp9;
        for(let i =1; i<= liczba_pol;i++){
            temp9 = compare[i];
            temp7 = punkty.indexOf(temp9)
            finish[i] = temp7}
    // Leaderboard
        let i = 0
        function leaderboard(){
            i++
            let x = finish[i]
            const leaderdiv1 = document.createElement("a");
            leaderdiv1.id = `m`;
            leaderdiv1.innerHTML = `Miejsce ${i} `;
            document.getElementById("scoreboard").appendChild(leaderdiv1)
            document.getElementById("scoreboard").appendChild(document.createElement("br"));
            const leaderdiv2 =  document.createElement("a");
            leaderdiv2.id = `i`;
            leaderdiv2.innerHTML = `${zawodnicy[x]} `;
            document.getElementById("scoreboard").appendChild(leaderdiv2)
            document.getElementById("scoreboard").appendChild(document.createElement("br"));
            const leaderdiv3 = document.createElement("a")
            leaderdiv3.id = `p`;
            leaderdiv3.innerHTML = `Punkty: ${punkty[x].toFixed(2)} `;
            document.getElementById("scoreboard").appendChild(leaderdiv3);
            document.getElementById("scoreboard").appendChild(document.createElement("br"));
            const leaderdiv4 = document.createElement("a")
            leaderdiv4.id = `o`;
            leaderdiv4.innerHTML = `Odległość: ${zawodnicy_od[x]} metrów `;
            document.getElementById("scoreboard").appendChild(leaderdiv4);
            const leaderdiv5 = document.createElement("a")
            leaderdiv5.id = `n`;
            leaderdiv5.innerHTML = `Noty Sędziowskie: ${notysedziowie[i]}`;
            document.getElementById("scoreboard").appendChild(leaderdiv5);
            const leaderdiv6 = document.createElement("a")
            leaderdiv6.id = `w`;
            leaderdiv6.innerHTML = `Wiatr: ${wiatr[x]} m/s`;
            document.getElementById("scoreboard").appendChild(leaderdiv6);
            document.getElementById("scoreboard").appendChild(document.createElement("br"));
            document.getElementById("scoreboard").appendChild(document.createElement("br"));
        }
        finish.forEach(leaderboard)
        // (C) 2023 Szafruga Software, All rights reserved.
        }