<template>
    <v-app>
      <Navbar />
      <v-main class="notfound" >
        <v-container>
          <v-row justify="center">
            <v-col cols="12" md="8" lg="6" class="text-center">
              <h1 class="text-center">404</h1><br>
              <h1 class="text-center">Requested page could not be found.</h1>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
      <Footer />
    </v-app>
  </template>
  
  <script>
  import Navbar from './components/Navbar.vue';
  import Footer from './components/Footer.vue';
  
  export default {
    name: 'Ho-me',
    components: {
      Navbar,
      Footer,
    },
  };
  </script>
  
  <style scoped>
  
  .notfound{
    background-color: midnightblue;
    color: white;
  }
  
  .fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-out forwards;
  }
  
  .fade-in-delayed {
    opacity: 0;
    animation: fadeIn 1s ease-out forwards;
    animation-delay: 0.5s;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  </style>