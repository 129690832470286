<template>
    <v-app-bar app color="secondary" dark>
      <v-toolbar-title>Szafruga Software Inc.</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text to="/">Home</v-btn>
      <v-btn text to="/projects">Projects</v-btn>
      <v-btn text to="/about">About</v-btn>
    </v-app-bar>
  </template>
  
  <script>
  export default {
    name: 'Nav-bar',
  };
  </script>
  
  <style scoped>
  </style>
  