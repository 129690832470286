<template>
    <v-app>
      <v-main class="main-content">
        <router-view />
      </v-main>
    </v-app>
  </template>
  
  <script>
  export default {
    name: 'Ma-in'
}
  </script>
  
  <style scoped>

  </style>