import { createApp } from 'vue';
import App from './Main.vue';
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import router from './router';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'mainTheme',
    themes: {
      mainTheme: {
        dark: true,
        colors: {
          primary: '#191970',
          secondary: '#212196', 
          background: '#f5f5f5',
          surface: '#ffffff',
          error: '#e74c3c',
          info: '#3498db',
          success: '#2ecc71',
          warning: '#f1c40f',
        },   
      },
      },
    },
  });
  const app = createApp(App);
  app.use(router);
  app.use(vuetify);
  app.mount('#app');